import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import PageMetaData from 'components/PageMetaData';
import {MultiGrid, RequesterViewType} from 'components/MultiGrid';
import {CardActionType} from 'components/MultiGrid';
import * as DashboardStore from 'stores/Dashboard';
import {getCookie} from 'utility';
@withRouter
@connect((state) => ({user: state.user,dashboard: state.dashboard}), DashboardStore.actionCreators)
export default class RgaOrders extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...DashboardStore.ActionShape,
        dashboard: DashboardStore.StateShape,
    };
    constructor(props) {
        super(props);
        
        const gridName = 'rgaColumns'
        const cachedViewType = getCookie(`requesterViewType-${gridName}`)
        const {dashboard: {myAccountFilter}} = props;
        this.state = {
            gridName,
            columns: [],
            view: 'Grid',
            orders: [],
            loading: true,
            requesterViewType: cachedViewType ? RequesterViewType[cachedViewType] : myAccountFilter.lineItemIds.length > 0 ? RequesterViewType.AllRequesters : RequesterViewType.CurrentUser,
        }
    }

    componentDidMount() {
        const {gridName} = this.state;
        const {requesterViewType} = this.state;
        const {user: {settings: {showAllRecords}}} = this.props;
        const showAllOrders = requesterViewType === RequesterViewType.AllRequesters;
        axios.get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`).then(x => {
            this.setState({columns: x.data.columns, view: x.data.view});
        });
        this.getData(showAllOrders);
    }

    getData(showAllRecords) {
        const {dashboard: {myAccountFilter}, resetMyAccountFilter} = this.props;
        this.setState({loading: true});
        let request = {
            showAllOrders: showAllRecords,
            lineItemIds: myAccountFilter.lineItemIds,
        };
        axios.post('/ShoppingService/api/v1/orders/rga', request).then(x => {
            if (myAccountFilter.lineItemIds) resetMyAccountFilter();
            this.setState({orders: x.data, loading: false});
        });
    }

    handleSelect(item) {
        const {history} = this.props;
        history.push(`/order/${item.lineItemId}`);
    }

    handleRequesterToggle(requesterViewType) {
        this.setState({requesterViewType})
        this.getData(requesterViewType === RequesterViewType.AllRequesters);
    }

    render() {
        const {gridName, columns, orders, view, loading, requesterViewType} = this.state;

        let gridKeys = [
            {
                id: 'exchanges',
                fieldName: 'rgaTypeId',
                value: '1',
                tabDisplay: 'Exchanges',
                cardAction: CardActionType.Exchange,
                height: '560',
            },
            {
                id: 'returns',
                fieldName: 'rgaTypeId',
                value: '2,4', tabDisplay: 'Returns',
                cardAction: CardActionType.Return,
                height: '560',
            },
        ];

        return (<div>
            <PageMetaData
                title="Rga Orders"
                pageType="other"
                trackAnalytics={true}
            />
            <MultiGrid
                onRequesterToggle={::this.handleRequesterToggle}
                showRequesterToggle={true}
                gridName={gridName}
                view={view}
                gridKeys={gridKeys}
                data={orders}
                columns={columns}
                label="My Account"
                subLabel="Exchanges & Returns"
                onRefresh={::this.getData}
                onSelect={::this.handleSelect}
                loadingData={loading}
                requesterViewType={requesterViewType}
            />
        </div>)
    }
}
